import React from "react";
import bgland from "../../images/bgland.jpg";
import geneticImg from "../../images/genetic.jpg";
import bgImg6 from "../../images/bgImg6.jpg";

function HeroSection() {
  return (
    <>
      <div className="relative flex flex-col items-center min-h-screen overflow-hidden bg-opacity-40 backdrop-blur-md">
        <div
          className="absolute inset-0 w-full h-full bg-no-repeat bg-cover bg-center blur-sm"
          style={{
            backgroundImage: `url(${bgImg6})`,
            backgroundPosition: "center",
            backgroundSize: "100% auto",
          }}
        ></div>
        <div className="relative flex flex-col items-center w-full bg-blue-400 bg-opacity-60 mx-4 z-10 pt-20 pb-10 sm:pt-40 sm:pb-20">
          <h1 className="text-3xl sm:text-6xl lg:text-7xl text-center tracking-wide animate-slide-in-right">
            <span className="font-semibold items-start font-roman bg-gradient-to-r from-gray-600 to-blue-600 text-transparent text-opacity-50 bg-clip-text">
              Precision Testings For
            </span>
          </h1>
          <h1 className="text-3xl sm:text-5xl lg:text-6xl text-center tracking-wide animate-slide-in-right">
            <span className="font-semibold font-roman bg-gradient-to-r from-gray-600 to-blue-700 text-transparent bg-clip-text">
              Healthier Tomorrow
            </span>
          </h1>
          <div className="relative z-10">
            <p className="mt-10 mb-10 font-source-sans text-[20px] text-center text-gray-900 max-w-4xl">
              Are you curious about your health? Our state-of-the-art lab tests
              provide you with essential insights into your body, helping you
              make informed health decisions. Whether you’re looking to
              understand your genetic makeup, assess your metabolic function, or
              monitor specific health markers, our tests are designed with you
              in mind.
            </p>
          </div>
          <div className="flex flex-col md:flex-row mt-10 justify-center object-cover z-10">
            <div className="rounded-lg  md:w-1/2  mx-4 my-6 relative overflow-hidden">
              <img
                src={bgland}
                alt="Placeholder Image"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="rounded-lg  md:w-1/2 mx-4 my-6 relative overflow-hidden">
              <img
                src={geneticImg}
                alt="Placeholder Image"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
