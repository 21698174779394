import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "lucide-react";
import TermsAndConditionsModal from "../termsAndConditionsModal/TermsAndConditionsModal";
function PersonalInformationForm({ formData, setFormData }) {
  const [modalVisible, setModalVisible] = useState(false);
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: date,
    }));
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };
  const isDatePassedOrPresent = (date) => {
    const today = new Date();
    return date <= today;
  };

  return (
    <div className="space-y-4">
      <h1 className="text-xl font-bold text-center">Personal Information</h1>
      <div className="flex flex-col sm:flex-row gap-4">
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          placeholder="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required={true}
        />
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required={true}
        />
      </div>
      <div className="flex gap-4">
        <div className="relative flex-grow">
          <DatePicker
            selected={formData.dateOfBirth}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            placeholderText="DOB"
            className="w-full px-2 py-2 pr-12 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            filterDate={isDatePassedOrPresent}
            required
          />
          <Calendar
            className="absolute right-1.5 sm:right-2 xl:right-14  top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
            size={20}
          />
        </div>
        <select
          className="w-full md:w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          name="gender"
          value={formData.gender}
          onChange={handleChange}
          required
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="notToSay">Prefer not to say</option>
          <option value="other">Other Specify</option>
        </select>
      </div>
      {formData.gender === "other" && (
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          placeholder="Specify Gender"
          name="otherGender"
          value={formData.otherGender}
          onChange={handleChange}
        />
      )}
      <div className="flex flex-col sm:flex-row gap-4">
        <input
          type="email"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Email Id*"
          name="email"
          value={formData.email}
          onChange={handleChange}
          maxLength={30}
          required={true}
        />
      </div>
      <div className="space-y-4">
        <span
          onClick={toggleModal}
          className=" text-red-800 active:underline cursor-pointer mt-4 hover:text-blue-600 active:text-blue-700"
        >
          Terms & Conditions
        </span>
      </div>
      <div className="flex items-start sm:items-center">
        <div>
          <input
            type="checkbox"
            id="agreeToShareDetails"
            name="agreeToShareDetails"
            checked={formData.agreeToShareDetails}
            onChange={handleChange}
            className="mr-2"
          />
        </div>
        <label htmlFor="agreeToShareDetails">I agree to share my details</label>
      </div>
      <div className="flex items-start sm:items-center">
        <div>
          <input
            type="checkbox"
            id="wantToGetInTouch"
            name="wantToGetInTouch"
            checked={formData.wantToGetInTouch}
            onChange={(e) =>
              setFormData({ ...formData, wantToGetInTouch: e.target.checked })
            }
            className="mr-2"
          />
        </div>
        <label htmlFor="wantToGetInTouch">
          I want to get in touch with the lab
        </label>
      </div>
      {formData.wantToGetInTouch && (
        <>
          <div>
            <input
              type="text"
              placeholder="Your Address"
              name="Address"
              value={formData.Address}
              onChange={handleChange}
              maxLength={120}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block mb-2">Preferred Contact Method:</label>
            <div className="space-x-4">
              {["Mobile"].map((value) => (
                <label key={value} className="inline-flex items-center">
                  <input
                    type="radio"
                    name="contactPreference"
                    value={value}
                    checked={formData.contactPreference === value}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        contactPreference: e.target.value,
                      })
                    }
                    className="mr-2"
                  />
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </label>
              ))}
            </div>
          </div>

          {formData.contactPreference === "Mobile" && (
            <div>
              <input
                type="tel"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber}
                maxLength={10}
                onChange={(e) => {
                  const value = e.target.value;

                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
              />
            </div>
          )}
        </>
      )}

      <TermsAndConditionsModal
        isVisible={modalVisible}
        onClose={toggleModal}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
}

export default PersonalInformationForm;
