import React from "react";
import { motion } from "framer-motion";

function ConfirmationPage({ formData }) {
  const entries = Object.entries(formData);

  const pairedEntries = [];

  for (let i = 0; i < entries.length; i += 2) {
    pairedEntries.push(entries.slice(i, i + 2));
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-blue-100 p-0 rounded-lg"
    >
      <div className="overflow-x-auto  rounded-2xl scrollbar-hidden mx-auto">
        <table className=" w-[100%] divide-y divide-gray-200 ">
          <thead className="bg-blue-200 rounded-2xl">
            <tr>
              <th className="p-4 text-left text-green-800 text-sm font-medium uppercase">
                Field
              </th>
              <th className="p-4 text-left text-green-800 text-sm font-medium uppercase">
                Value
              </th>
              <th className="p-4 text-left text-green-800 text-sm font-medium uppercase">
                Field
              </th>
              <th className="p-4 text-left text-green-800 text-sm font-medium uppercase">
                Value
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {pairedEntries.map((pair, index) => (
              <tr key={index}>
                {pair.map(([key, value], idx) => (
                  <React.Fragment key={idx}>
                    <td className="p-4 text-green-700 font-semibold text-sm w-1/4">
                      {key
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}
                    </td>
                    <td className="p-4 text-green-900 text-sm w-1/4">
                      {key === "dateOfBirth"
                        ? value
                          ? new Date(value).toLocaleDateString()
                          : "NA"
                        : typeof value === "boolean"
                        ? value
                          ? "Yes"
                          : "No"
                        : value || "NA"}
                    </td>
                  </React.Fragment>
                ))}
                {pair.length === 1 && (
                  <>
                    <td className="p-4"></td>
                    <td className="p-4"></td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
}

export default ConfirmationPage;
