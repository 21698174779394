import React from "react";
import herotest from "../../images/herotest.png";
import entiretest from "../../images/entiretest.jpg";
import image1 from "../../images/image1.png";
import image2 from "../../images/image2.png";
import image3 from "../../images/image3.png";
import image4 from "../../images/image4.jpg";


const checklistItems = [
  {
    title: "Sample Collection",
    description: "Gather samples easily and efficiently.",
    image: image1,
  },
  {
    title: "Testing Procedures",
    description: "Follow standardized protocols for accuracy.",
    image: image2,
  },
  {
    title: "Result Analysis",
    description: "Receive comprehensive insights from your tests.",
    image: image3,
  },
  {
    title: "Consultation",
    description: "Discuss results with our expert team.",
    image: image4,
  },
];

function Workflow() {
  return (
    
    <div className="relative mt-10 bg-blur-md bg-blue-300 bg-opacity-40 backdrop-blur-md">
      <div
        className="absolute inset-0 bg-cover bg-center bg-blue-300 bg-opacity-90"
        style={{
          backgroundImage: `url(${entiretest})`,
          filter: "blur(9.5px)",
        }}
      ></div>

      <div className="relative z-10 p-4">
        <div className=" mt-10">
          <h2 className="text-3xl  sm:text-5xl lg:text-6xl text-center tracking-wide">
            <span className="font-semibold mt-20 font-roman bg-gradient-to-r from-blue-600 via-blue-700 to-purple-700 text-transparent bg-clip-text">
              Efficient lab Testing workflow.
            </span>
          </h2>
        </div>
      </div>
      <div className="flex flex-wrap mt-10 justify-center">
        <div className="w-full lg:w-1/2 lg:p-8 ">
          {/* Image for the background */}
          <div className="h-72 flex object-cover items-center justify-center relative ">
            <img
              src={herotest}
              alt="Survey Background"
              className="absolute inset-0 h-96 w-full items-center object-cover"
            />
          </div>
        </div>
        <div className="relative z-10 pt-16 w-full lg:w-1/2">
          {checklistItems.map((item, index) => (
            <div key={index} className="flex mb-12 overflow-hidden">
              <div
                className="mx-6 h-10 w-10 p-6 justify-center items-center rounded-full border border-purple-700 shadow-sm shadow-orange-400"
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              <div>
                <h5 className="mt-1 font-sans mb-2 text-[25px] text-blue-800">
                  {item.title}
                </h5>
                <p className="text-md italic text-[20px] text-neutral-700">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Workflow;
