import React from "react";

function TermsAndConditionsModal({
  isVisible,
  onClose,
  formData,
  setFormData,
}) {
  if (!isVisible) return null;

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div className="flex shadow-lg rounded-2xl justify-center overflow-hidden p-0 w-full h-[75%] bg-blue-100">
      <div
        className="flex fixed inset-0 bg-red bg-opacity-50 justify-center items-center z-999"
        onClick={onClose}
      >
        <div
          className="bg-slate-700 p-6 rounded-2xl shadow-lg"
          onClick={(e) => e.stopPropagation()}
        >
          <h3 className="font-semibold text-lg text-center justify-center text-white">
            Terms & Conditions
          </h3>

          <div className="flex items-start">
            <div>
              <input
                type="checkbox"
                id="termsAndConditions"
                name="termsAndConditions"
                checked={formData.termsAndConditions}
                className="mr-2"
                onChange={handleChange}
              />
            </div>
            <label htmlFor="termsAndConditions" className="text-white">
              I understand that this pharmacogenetic test will analyze my DNA
              for variations that may affect how my body processes certain
              medications. I consent to this genetic analysis and understand
              that the results will be shared with my healthcare provider to
              guide medication decisions.
            </label>
          </div>
          <button
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditionsModal;
