import React from "react";

const resourcesLinks = [
  { href: "#", text: "Documentation" },
  { href: "#", text: "API Reference" },
  { href: "#", text: "Support" },
];

const platformLinks = [
  { href: "#", text: "Pricing" },
  { href: "#", text: "Integrations" },
  { href: "#", text: "Features" },
];

const communityLinks = [
  { href: "#", text: "Forums" },
  { href: "#", text: "Blog" },
  { href: "#", text: "Meetups" },
];

function Footer() {
  return (
    <footer className="mt-10 border-t bg-white bg-opacity-50 py-10 border-blue-200">
      <div className="grid grid-cols-2 justify-center items-center lg:grid-cols-3 gap-4">
        <div>
          <h3 className="text-lg font-semibold text-neutral-800 mb-4">
            Resources
          </h3>
          <ul className="space-y-2">
            {resourcesLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className="text-neutral-700 hover:text-neutral-900"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-neutral-800 mb-4">
            Platform
          </h3>
          <ul className="space-y-2">
            {platformLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className="text-neutral-700 hover:text-neutral-900"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-neutral-800 mb-4">
            Community
          </h3>
          <ul className="space-y-2">
            {communityLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className="text-neutral-700 hover:text-neutral-900"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
