const formDataJsons = {
  pgx: [
    {
      formName: "Current Medications",
      question: [
        {
          questionId: "medications_1",
          questionHeading: "",
          questionText:
            "Are you taking any of the following types of medications? (Check all that apply)",
          subQuestions: [
            {
              subQuestionId: "medications_statins",
              subQuestionHeading:
                "Statins for high cholesterol (e.g., atorvastatin, simvastatin)",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "medications_antidepressants",
              subQuestionHeading:
                "Antidepressants (e.g., SSRIs, SNRIs, tricyclics)",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "medications_cancer",
              subQuestionHeading: "Cancer medications",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "medications_blood_thinners",
              subQuestionHeading:
                "Blood thinners (e.g., warfarin, clopidogrel)",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "medications_hiv",
              subQuestionHeading: "HIV medications",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "medications_immunosuppressants",
              subQuestionHeading: "Immunosuppressants",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "medications_others",
              subQuestionHeading: "Others",
              subQuestionType: "checkbox",
              subQuestionText: "",
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "medications_others_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      formName: "Detailed Medical History",
      question: [
        {
          questionId: "medical_history_cardiovascular",
          questionHeading: "Cardiovascular",
          questionText:
            "Do you have any of the following cardiovascular conditions? Please check all that apply:",
          subQuestions: [
            {
              subQuestionId: "medical_history_high_cholesterol",
              subQuestionType: "checkbox",
              subQuestionHeading: "High cholesterol",
              subQuestionText: "",
            },
            {
              subQuestionId: "medical_history_high_blood_pressure",
              subQuestionType: "checkbox",
              subQuestionHeading: "High blood pressure",
              subQuestionText: "",
            },
            {
              subQuestionId: "medical_history_heart_attack",
              subQuestionType: "checkbox",
              subQuestionHeading: "History of heart attack or stroke",
              subQuestionText: "",
            },
            {
              subQuestionId: "medical_history_others",
              subQuestionType: "checkbox",
              subQuestionHeading: "Others",
              subQuestionText: "",
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "medical_history_others_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "medical_history_mental_health",
          questionHeading: "Mental Health",
          questionText:
            "Do you have any of the following mental health conditions? Please check all that apply:",
          subQuestions: [
            {
              subQuestionId: "mental_health_depression",
              subQuestionType: "checkbox",
              subQuestionHeading: "Depression",
              subQuestionText: "",
            },
            {
              subQuestionId: "mental_health_anxiety",
              subQuestionType: "checkbox",
              subQuestionHeading: "Anxiety",
              subQuestionText: "",
            },
            {
              subQuestionId: "mental_health_bipolar",
              subQuestionType: "checkbox",
              subQuestionHeading: "Bipolar disorder",
              subQuestionText: "",
            },
            {
              subQuestionId: "mental_health_other_conditions",
              subQuestionType: "checkbox",
              subQuestionHeading: "Others",
              subQuestionText: "",
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "mental_health_others_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "medical_history_cancer_type",
          questionHeading: "Cancer",
          questionText: "What type of cancer have you had?",
          subQuestions: [
            {
              subQuestionId: "cancer_type_input",
              subQuestionType: "text",
              subQuestionHeading: "",
              subQuestionText: "",
            },
          ],
        },
        {
          questionId: "medical_history_cancer_diagnosis_date",
          questionHeading: "",
          questionText: "What was the date of your diagnosis?",
          subQuestions: [
            {
              subQuestionId: "cancer_diagnosis_date",
              subQuestionType: "date",
              subQuestionHeading: "",
              subQuestionText: "",
            },
          ],
        },
        {
          questionId: "medical_history_current_status",
          questionHeading: "",
          questionText: "What is your current status?",
          subQuestions: [
            {
              subQuestionId: "current_status_dropdown",
              subQuestionType: "dropdown",
              subQuestionHeading: "",
              subQuestionText: "",
              options: ["In treatment", "In remission", "Cured"],
            },
          ],
        },
        {
          questionId: "medical_history_blood_disorders",
          questionHeading: "Blood Disorders",
          questionText: "Do you have any history of blood clots?",
          subQuestions: [
            {
              subQuestionId: "blood_disorders_radio",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "blood_disorders_date",
                  needFUrtherInfoQuestionType: "date",
                  needFUrtherInfoHeading: "When did you have blood clots?",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "medical_history_bleeding_problem",
          questionHeading: "",
          questionText: "Do you have a bleeding problem?",
          subQuestions: [
            {
              subQuestionId: "bleeding_problem_input",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "medical_history_infectious_diseases_hiv",
          questionHeading: "Infectious Diseases",
          questionText: "Do you have HIV?",
          subQuestions: [
            {
              subQuestionId: "hiv_diagnosis_radio",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "hiv_diagnosis_date",
                  needFUrtherInfoQuestionType: "date",
                  needFUrtherInfoHeading:
                    "Please provide the date of diagnosis.",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "medical_history_infectious_hepatitis",
          questionHeading: "",
          questionText: "Do you have hepatitis?",
          subQuestions: [
            {
              subQuestionId: "hepatitis_specify",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "hepatitis_specify_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading:
                    "Please specify the type of diagnosis.",
                  needFUrtherInfonText: "",
                },
                {
                  needFurtherInfoId: "hepatitis_specify_date",
                  needFUrtherInfoQuestionType: "date",
                  needFUrtherInfoHeading:
                    "Please specify the date of diagnosis.",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "medical_history_other_infectious_diseases",
          questionHeading: "",
          questionText:
            "Do you have any other infectious diseases? Please specify.",
          subQuestions: [
            {
              subQuestionId: "other_infectious_diseases_specify",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "infectious_diseases_specify_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading:
                    "Please specify the other infectious diseases.",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "medical_history_autoimmune_disorders",
          questionHeading: "Autoimmune Disorders",
          questionText:
            "Ignore if not applicable. Do you have any of the following autoimmune conditions? Please check all that apply.",
          subQuestions: [
            {
              subQuestionId: "autoimmune_disorders_rheumatoid",
              subQuestionType: "checkbox",
              subQuestionHeading: "Rheumatoid Arthritis",
              subQuestionText: "",
            },
            {
              subQuestionId: "autoimmune_disorders_lupus",
              subQuestionType: "checkbox",
              subQuestionHeading: "Lupus",
              subQuestionText: "",
            },
            {
              subQuestionId: "autoimmune_disorders_multiple_sclerosis",
              subQuestionType: "checkbox",
              subQuestionHeading: "Multiple sclerosis",
              subQuestionText: "",
            },
            {
              subQuestionId: "autoimmune_disorders_other_conditions",
              subQuestionType: "checkbox",
              subQuestionHeading: "Others",
              subQuestionText: "",
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "autoimmune_disorders_others_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "medical_history_organ_transplants",
          questionHeading: "Organ Transplants",
          questionText: "What type of transplant have you received?",
          subQuestions: [
            {
              subQuestionId: "transplant_type_input",
              subQuestionType: "text",
              subQuestionHeading: "",
              subQuestionText: "",
            },
          ],
        },
        {
          questionId: "medical_history_transplant_date",
          questionHeading: "",
          questionText: "What was the date of your transplant?",
          subQuestions: [
            {
              subQuestionId: "transplant_date",
              subQuestionType: "date",
              subQuestionHeading: "",
              subQuestionText: "",
            },
          ],
        },
        {
          questionId: "medical_history_other_conditions",
          questionHeading: "Other Medical History",
          questionText:
            "Please list any other significant medical conditions not covered in the previous sections:",
          subQuestions: [
            {
              subQuestionId: "other_conditions_input",
              subQuestionType: "text",
              subQuestionHeading: "",
              subQuestionText: "",
            },
          ],
        },
      ],
    },
    {
      formName: "Detailed Medication Reactions",
      question: [
        {
          questionId: "medication_reactions_unusual_reaction",
          questionHeading: "",
          questionText:
            "Have you ever experienced an unusual or severe reaction to any medication?",
          subQuestions: [
            {
              subQuestionId: "medication_reactions_radio",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "medication_reactions_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "Medication Name",
                  needFUrtherInfonText: "Medication Name",
                },
                {
                  needFurtherInfoId: "medication_reactions_text_1",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "Reaction Description",
                  needFUrtherInfonText: "Reaction Description",
                },
                {
                  needFurtherInfoId: "medication_reactions_dropdown",
                  needFUrtherInfoQuestionType: "dropdown",
                  needFUrtherInfoHeading: "Severity ",
                  needFUrtherInfonText: "Severity",
                  options: ["Mild", "Moderate", "Severe"],
                },
                {
                  needFurtherInfoId: "medication_reactions_text_2",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "Duration ",
                  needFUrtherInfonText: "Duration",
                },
                {
                  needFurtherInfoId: "medication_reactions_text_3",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "Action Taken",
                  needFUrtherInfonText: "Action Taken",
                },
              ],
            },
          ],
        },
        {
          questionId: "medication_reactions_genetic_testing",
          questionHeading: "",
          questionText:
            "Have you ever had genetic testing that showed you were at risk for medication side effects?",
          subQuestions: [
            {
              subQuestionId: "genetic_testing_radio",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText:
                "Have you ever had genetic testing that showed you were at risk for medication side effects?",
              options: ["Yes", "No", "Unsure"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "genetic_testing_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      formName: "Family History",
      question: [
        {
          questionId: "family_history_smoking",
          questionHeading: "",
          questionText: "Do you smoke?",
          subQuestions: [
            {
              subQuestionId: "smoke_radio",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "Do you smoke?",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "family_history_alcohol",
          questionHeading: "",
          questionText: "Do you consume alcohol?",
          subQuestions: [
            {
              subQuestionId: "alcohol_radio",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "alcohol_text",
                  needFUrtherInfoQuestionType: "dropdown",
                  needFUrtherInfoHeading: "How often do you consume alcohol",
                  needFUrtherInfonText: "",
                  options: ["Daily", "Weekly", "Monthly", "Occasionally"],
                },
              ],
            },
          ],
        },
        {
          questionId: "family_history_specific_diet",
          questionHeading: "",
          questionText: "Do you follow any specific diet?",
          subQuestions: [
            {
              subQuestionId: "specific_diet_radio",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "Do you follow any specific diet?",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "specific_diet_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "Please specify the type of diet.",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      formName: "Lifestyle Factors",
      question: [
        {
          questionId: "lifestyle_family_reactions",
          questionHeading: "",
          questionText:
            "Has anyone in your immediate family experienced unusual reactions to medications?",
          subQuestions: [
            {
              subQuestionId: "family_medication_reactions",
              subQuestionType: "radio",
              subQuestionHeading: "",
              subQuestionText: "",
              options: ["Yes", "No", "Unsure"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "family_medication_reactions_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  rpx: [
    {
      formName: "Medical History",
      question: [
        {
          questionId: "symptom_experience",
          questionHeading: "",
          questionText: "Are you experiencing any of the following symptoms?",
          subQuestions: [
            {
              subQuestionId: "symptom_experience_sq1",
              subQuestionHeading: "Coughing",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq2",
              subQuestionHeading: "Sneezing",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq3",
              subQuestionHeading: "Stuffy or runny nose",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq4",
              subQuestionHeading: "Sore throat",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq5",
              subQuestionHeading: "Headache",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq6",
              subQuestionHeading: "Weakness or fatigue",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq7",
              subQuestionHeading: "Muscle aches",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq8",
              subQuestionHeading: "Fever or chills",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq9",
              subQuestionHeading: "Wheezing or difficulty breathing",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq10",
              subQuestionHeading: "Low appetite",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq11",
              subQuestionHeading: "Diarrhea",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq12",
              subQuestionHeading: "Vomiting",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "symptom_experience_sq13",
              subQuestionHeading: "Others",
              subQuestionType: "checkbox",
              subQuestionText: "",
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "symptom_experience_Others_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "symptom_duration",
          questionHeading: "",
          questionText: "How long have you been experiencing these symptoms?",
          subQuestions: [
            {
              subQuestionId: "symptom_duration_sq1",
              subQuestionHeading: "",
              subQuestionType: "dropdown",
              subQuestionText: "",
              options: [
                "Less than 24 hours",
                "1-3 days",
                "4-7 days",
                "More than a week",
              ],
            },
          ],
        },
        {
          questionId: "high_risk_categories",
          questionHeading: "",
          questionText:
            "Do you fall into any of the following high-risk categories?",
          subQuestions: [
            {
              subQuestionId: "high_risk_categories_sq1",
              subQuestionHeading: "",
              subQuestionType: "dropdown",
              subQuestionText: "",
              options: [
                "Very young (infant or young child)",
                "Elderly (65 years or older)",
                "Weakened (compromised) immune system",
                "Pregnant",
                "Living in close quarters (e.g., dormitory, nursing home, military barracks)",
              ],
            },
          ],
        },
        {
          questionId: "recent_contact_infection",
          questionHeading: "",
          questionText:
            "Have you recently been in close contact with someone who has a respiratory infection?",
          subQuestions: [
            {
              subQuestionId: "recent_contact_infection_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No", "Unsure"],
            },
          ],
        },
        {
          questionId: "cold_flu_season",
          questionHeading: "",
          questionText:
            "Is it currently cold and flu season (late fall through early spring)?",
          subQuestions: [
            {
              subQuestionId: "cold_flu_season_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "flu_vaccination",
          questionHeading: "",
          questionText: "Have you received a flu vaccination this year?",
          subQuestions: [
            {
              subQuestionId: "flu_vaccination_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "hospitalization_consideration",
          questionHeading: "",
          questionText:
            "Are your symptoms severe enough to consider hospitalization?",
          subQuestions: [
            {
              subQuestionId: "hospitalization_consideration_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No", "Unsure"],
            },
          ],
        },
        {
          questionId: "recent_travel_outbreak",
          questionHeading: "",
          questionText:
            "Have you traveled recently to an area with known respiratory disease outbreaks?",
          subQuestions: [
            {
              subQuestionId: "recent_travel_outbreak_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "pre_existing_conditions",
          questionHeading: "",
          questionText: "Do you have any pre-existing respiratory conditions?",
          subQuestions: [
            {
              subQuestionId: "pre_existing_conditions_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "pre_existing_conditions_sq1_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "medications_currently_taking",
          questionHeading: "",
          questionText:
            "Are you currently taking any medications for your symptoms?",
          subQuestions: [
            {
              subQuestionId: "medications_currently_taking_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "medications_currently_taking_sq1_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
        {
          questionId: "symptom_improvement",
          questionHeading: "",
          questionText:
            "Have you noticed any improvement in your symptoms with over-the-counter medications?",
          subQuestions: [
            {
              subQuestionId: "symptom_improvement_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No", "Haven't tried any"],
            },
          ],
        },
        {
          questionId: "concerning_symptoms",
          questionHeading: "",
          questionText:
            "Are you experiencing any symptoms that are particularly concerning or different from a typical cold or flu?",
          subQuestions: [
            {
              subQuestionId: "concerning_symptoms_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
              subQuestionNeedFUrtherInfo: [
                {
                  needFurtherInfoId: "concerning_symptoms_sq1_text",
                  needFUrtherInfoQuestionType: "text",
                  needFUrtherInfoHeading: "",
                  needFUrtherInfonText: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],

  hx: [
    {
      formName: "HBOC Syndrome Testing",
      question: [
        {
          questionId: "breast_cancer_family",
          questionHeading: "",
          questionText:
            "Do you have any close family members who were diagnosed with breast cancer at or before the age of 50?",
          subQuestions: [
            {
              subQuestionId: "breast_cancer_family_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "triple_negative_cancer_family",
          questionHeading: "",
          questionText:
            "Has any close family member been diagnosed with triple-negative breast cancer at any age?",
          subQuestions: [
            {
              subQuestionId: "triple_negative_cancer_family_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "male_breast_cancer_relatives",
          questionHeading: "",
          questionText:
            "Do you have any male relatives who have been diagnosed with breast cancer at any age?",
          subQuestions: [
            {
              subQuestionId: "male_breast_cancer_relatives_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "ovarian_cancer_family",
          questionHeading: "",
          questionText:
            "Has anyone in your close family been diagnosed with ovarian cancer at any age?",
          subQuestions: [
            {
              subQuestionId: "ovarian_cancer_family_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "pancreatic_cancer_history",
          questionHeading: "",
          questionText:
            "Does anyone in your close family have a history of pancreatic cancer?",
          subQuestions: [
            {
              subQuestionId: "pancreatic_cancer_history_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "family_high_risk_cancer",
          questionHeading: "",
          questionText:
            "Have you been informed that any of your family members with cancer belong to a high-risk or very-high-risk group?",
          subQuestions: [
            {
              subQuestionId: "family_high_risk_cancer_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "family_cancer_count",
          questionHeading: "",
          questionText:
            "How many close family members in total have been diagnosed with either breast or prostate cancer?",
          subQuestions: [
            {
              subQuestionId: "family_cancer_count_sq1",
              subQuestionHeading: "",
              subQuestionType: "dropdown",
              subQuestionText: "",
              options: ["0", "1", "2", "more"],
            },
          ],
        },
        {
          questionId: "ashkenazi_jewish_ancestry",
          questionHeading: "",
          questionText: "Are you of Ashkenazi Jewish ancestry?",
          subQuestions: [
            {
              subQuestionId: "ashkenazi_jewish_ancestry_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "family_cancer_history_description",
          questionHeading: "",
          questionText:
            "Which of the following best describes your family cancer history?",
          subQuestions: [
            {
              subQuestionId: "family_cancer_history_description_sq1",
              subQuestionHeading:
                "Breast cancer in family member(s) aged 50 or younger",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "family_cancer_history_description_sq2",
              subQuestionHeading: "Triple-negative breast cancer",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "family_cancer_history_description_sq3",
              subQuestionHeading: "Male breast cancer",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "family_cancer_history_description_sq4",
              subQuestionHeading: "Ovarian cancer",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "family_cancer_history_description_sq5",
              subQuestionHeading: "Pancreatic cancer",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "family_cancer_history_description_sq6",
              subQuestionHeading:
                "Multiple cases of breast and/or prostate cancer",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "family_cancer_history_description_sq7",
              subQuestionHeading: "None of the above",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
          ],
        },
      ],
    },
    {
      formName: "Lynch Syndrome Testing",
      question: [
        {
          questionId: "colorectal_cancer_diagnosis",
          questionHeading: "",
          questionText: "Have you been diagnosed with colorectal cancer?",
          subQuestions: [
            {
              subQuestionId: "colorectal_cancer_diagnosis_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
        {
          questionId: "colorectal_cancer_age",
          questionHeading: "",
          questionText:
            "If yes in above question, were you diagnosed before the age of 50?",
          subQuestions: [
            {
              subQuestionId: "colorectal_cancer_age_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No", "Not Applicable"],
            },
          ],
        },
        {
          questionId: "lynch_related_cancers",
          questionHeading: "",
          questionText:
            "Have you been diagnosed with any of the following Lynch Syndrome-related cancers?",
          subQuestions: [
            {
              subQuestionId: "lynch_related_cancers_sq1",
              subQuestionHeading: "Endometrial cancer",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "lynch_related_cancers_sq2",
              subQuestionHeading: "Gastric",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "lynch_related_cancers_sq3",
              subQuestionHeading: "Ovarian",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "lynch_related_cancers_sq4",
              subQuestionHeading: "Pancreatic",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "lynch_related_cancers_sq5",
              subQuestionHeading: "Urothelial",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "lynch_related_cancers_sq6",
              subQuestionHeading: "Biliary tract",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "lynch_related_cancers_sq7",
              subQuestionHeading: "Small intestine",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "lynch_related_cancers_sq8",
              subQuestionHeading: "Brain (Glioblastoma)",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
            {
              subQuestionId: "lynch_related_cancers_sq9",
              subQuestionHeading: "None of the above",
              subQuestionType: "checkbox",
              subQuestionText: "",
            },
          ],
        },
        {
          questionId: "colorectal_and_lynch_cancer",
          questionHeading: "",
          questionText:
            "If you have had colorectal cancer, were you also diagnosed with another Lynch Syndrome-related cancer either at the same time or later?",
          subQuestions: [
            {
              subQuestionId: "colorectal_and_lynch_cancer_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No", "Not applicable"],
            },
          ],
        },
        {
          questionId: "genetic_testing_mmr_deficiency",
          questionHeading: "",
          questionText:
            "Have you ever had genetic testing that showed MMR (Mismatch Repair) deficiency in a tumor?",
          subQuestions: [
            {
              subQuestionId: "genetic_testing_mmr_deficiency_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No", "Unsure"],
            },
          ],
        },
        {
          questionId: "mmr_deficiency_test_type",
          questionHeading: "",
          questionText:
            "If yes in above question, do you know which test was used to determine MMR deficiency?",
          subQuestions: [
            {
              subQuestionId: "mmr_deficiency_test_type_sq1",
              subQuestionHeading: "",
              subQuestionType: "dropdown",
              subQuestionText: "",
              options: [
                "PCR",
                "NGS (Next-Generation Sequencing)",
                "IHC (Immunohistochemistry)",
                "None of the above",
              ],
            },
          ],
        },
        {
          questionId: "lynch_risk_assessment",
          questionHeading: "",
          questionText:
            "Have you ever had a risk assessment for Lynch Syndrome using a model like PREMM?",
          subQuestions: [
            {
              subQuestionId: "lynch_risk_assessment_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No", "Unsure"],
            },
          ],
        },
        {
          questionId: "premm_risk_score",
          questionHeading: "",
          questionText:
            "If yes in above question, do you know if your PREMM risk score was greater than 2.5%?",
          subQuestions: [
            {
              subQuestionId: "premm_risk_score_sq1",
              subQuestionHeading: "",
              subQuestionType: "dropdown",
              subQuestionText: "",
              options: ["Yes", "No", "Unsure", "Not applicable"],
            },
          ],
        },
        {
          questionId: "lynch_conditions_diagnosis",
          questionHeading: "",
          questionText:
            "Have you been diagnosed with any of the following conditions?",
          subQuestions: [
            {
              subQuestionId: "lynch_conditions_diagnosis_sq1",
              subQuestionHeading: "",
              subQuestionType: "dropdown",
              subQuestionText: "",
              options: [
                "Sebaceous adenoma",
                "Sebaceous carcinoma",
                "Muir-Torre Syndrome (Keratoacanthoma)",
                "None of the above",
              ],
            },
          ],
        },
        {
          questionId: "healthcare_professional_suggestion",
          questionHeading: "",
          questionText:
            "Has a healthcare professional ever suggested that you might be at increased risk for Lynch Syndrome?",
          subQuestions: [
            {
              subQuestionId: "healthcare_professional_suggestion_sq1",
              subQuestionHeading: "",
              subQuestionType: "radio",
              subQuestionText: "",
              options: ["Yes", "No"],
            },
          ],
        },
      ],
    },
  ],
};

export default formDataJsons;
