import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import Landing from "./components/landingPage/Landing.js";
import MultiStepRegistrationForms from "./components/multiStepRegistrationForms/MultiStepRegistrationForms.js";
import formDataJsons from "./components/questionData/formDatas.js";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Landing />} />
      {Object.keys(formDataJsons).map((key) => (
        <Route
          key={key}
          path={`/form/${key}`}
          element={<MultiStepRegistrationForms testopt={key} />}
        />
      ))}
    </>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
