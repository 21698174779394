import React from "react";
import bgtest2 from "../../images/bgtest2.jpg";
import genetic from "../../images/genetic.jpg";


const careOptions = [
  {
    title: "Empower your health with tests.",
    description:
      "Regular lab testing can help detect potential health issues early.",
    quotes: [
      "Start believing, that proactive health management starts with understanding your body.",
      "A comprehensive lab testings leads to informed health decisions.",
    ],
    image: genetic,
  },
  {
    title: "Stay proactive about your health.",
    description: "Know your health status with our regular lab tests.",
    quotes: [
      "Comprehensive lab testing provides the insights needed for informed health decisions.",
      "Proactive health management starts with understanding your body.",
    ],
    image: genetic,
  },
  {
    title: "Discover your health insights.",
    description:
      "Get comprehensive lab testing for a clearer picture of your health.",
    quotes: [
      "Understanding your health is key to proactive wealth management and beauty .",
      "Make informed health decisions with our lab testing services.",
    ],
    image: genetic,
  },
];

function CareForYou() {
  return (
    <div className="relative mt-10 bg-blur-md backdrop-blur-md overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-no-repeat bg-center bg-blue-300 bg-opacity-90"
        style={{
          backgroundImage: `url(${bgtest2})`,
          filter: "blur(7px)", 
        }}
      ></div>

      <h2 className="relative mt-10 text-3xl sm:text-5xl lg:text-6xl text-center my-8 tracking-wide">
        <span className="font-semibold font-roman bg-gradient-to-r from-blue-600 via-blue-700 to-purple-700 text-transparent bg-clip-text">
          {" "}
          Your Decision
        </span>
        <span className="font-semibold font-roman bg-gradient-to-r from-blue-600 via-blue-700 to-purple-800 text-transparent bg-clip-text">
          {" "}
          Your Health
        </span>
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 gap-6 p-6">
        {careOptions.map((option, index) => (
          <div key={index} className="w-full">
            <div className="p-8 bg-blue-400 bg-opacity-70 backdrop-blur-md border border-blue-300 shadow-md shadow-blue-400 rounded-xl sm:h-auto md:h-96 lg:h-96">
              <h3 className="text-2xl text-[30px] text-blue-700 mb-4">
                {option.title}
              </h3>
              <p className="mb-6 text-neutral-700 text-[20px]">
                {option.description}
              </p>
              <ul className="list-disc list-inside mb-4">
                {option.quotes.map((quote, idx) => (
                  <li
                    key={idx}
                    className="mt-2 text-gray-600 text-[18px] italic"
                  >
                    "{quote}"
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CareForYou;
