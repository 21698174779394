import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import topPic from "../../images/topPic.jpg";
import thanksImg from "../../images/thanku.jpg";
import PersonalInformationForm from "../personalInformationForm/PersonalInformationForm.js";
import ConfirmationPage from "../confirmationPage/ConfirmationPage.js";
import DynamicForm from "../dynamicForm/DynamicForm.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Bgimg from "../../images/Bgimg.png";
import AWS from "aws-sdk";
import {
  faArrowLeft,
  faHome,
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import PayorInformationForm from "../payorInformationForm/PayorInformationForm.js";
import payorOptions from "../questionData/payorQuestion.js";
import ThankuPage from "../thankuPage/ThankuPage.js";
import axios from "axios";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner.js";

const steps = [
  "Personal Information",
  "Payor Information",
  "Dynamic Form",
  "Thank You",
];
const MultiStepRegistrationForms = ({ onFormSubmit, testopt }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    otherGender: "",
    termsAndConditions: false,
    Address: "",
    contactPreference: "",
    mobileNumber: "",
    email: "",
    agreeToShareDetails: false,
    wantToGetInTouch: false,
    memberId: "",
    paymentType: "",
    payorName: "",
    testing_name: testopt,
    flag: "",
  });

  const [showThankYou, setShowThankYou] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchLocationName(latitude, longitude);
          },
          (error) => {
            setLocation(null);
          }
        );
      } else {
        setLocation(null);
      }
    };

    const fetchLocationName = async (latitude, longitude) => {
      try {
        const response = await axios.get(
          `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=c4a0340f1a0047e5af9d341025484326`
        );

        const locationData = response.data.results[0].formatted;
        setLocation(locationData);
      } catch (error) {
        setLocation(null);
      }
    };

    getLocation();
  }, []);

  const validdateSteps = (step) => {
    switch (step) {
      case 0:
        return (
          formData.firstName &&
          formData.dateOfBirth &&
          formData.gender &&
          formData.termsAndConditions &&
          formData.contactPreference &&
          formData.Address &&
          formData.agreeToShareDetails &&
          formData.wantToGetInTouch &&
          formData.email &&
          formData.mobileNumber
        );

      case 1:
        return formData.memberId && formData.payorName;

      default:
        return true;
    }
  };

  const handleNext = async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");

      const result = await checkUserSubmission(
        formData.email,
        formData.testing_name,
        (formData.flag = "check")
      );
      setIsLoading(false);

      if (validdateSteps(activeStep)) {
        if (result.Status == true && result.Result == 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (result.Status == true && result.Result == 1) {
          setErrorMessage("You have already submitted the form.");
        } else if (result.Status == true && result.Result == 2) {
          setErrorMessage("Your form has been submitted successfully.");
        } else if (result.Status == true && result.Result == -1) {
          setErrorMessage("For invalid data input to backend from frontend.");
        } else {
          setErrorMessage(
            "An error occurred while processing to the next page. Please try again."
          );
        }
      } else {
        setErrorMessage("Please fill the data in the required fields.");
      }
    } catch (error) {
      setIsLoading(false);

      setErrorMessage("An error occurred. Please try again later.");
    }
  };
  const handleNext2 = () => {
    setErrorMessage("");
    if (validdateSteps(activeStep)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrorMessage("Please fill the data in the required fields.");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  AWS.config.update({
    region: "ap-south-1",
    credentials: new AWS.Credentials({
      accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_LAMBDA_ACCESS_SECRET,
    }),
  });

  const lambda = new AWS.Lambda();

  const checkUserSubmission = async (email, testing_name, flag) => {
    try {
      const params = {
        FunctionName: "insert_surveyform_response",
        Payload: JSON.stringify({
          email,
          testing_name,
          flag,
        }),
      };

      const response = await lambda.invoke(params).promise();

      if (response.FunctionError) {
        throw new Error(
          JSON.parse(response.Payload).errorMessage ||
            "An error occurred while processing your submission."
        );
      }
      const result = JSON.parse(response.Payload);
      return result;
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(`https://api.ipify.org/?format=json`)
        .then((response) => response.json())
        .then((data) => {
          return data.ip;
        });

      const updatedFormData = {
        ...formData,
        flag: "submit",
        ip: res,
        location: location,
      };

      const params = {
        FunctionName: "insert_surveyform_response",
        Payload: JSON.stringify(updatedFormData),
      };

      const response = await lambda.invoke(params).promise();

      if (response.FunctionError) {
        throw new Error(
          JSON.parse(response.Payload).errorMessage ||
            "An error occurred while processing your submission."
        );
      }

      const result = JSON.parse(response.Payload);

      setShowThankYou(true);
      if (onFormSubmit) {
        onFormSubmit();
      }
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(
        "An error occurred while submitting the form. Please try again."
      );

      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate("/");
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PersonalInformationForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 1:
        return (
          <PayorInformationForm
            formData={formData}
            setFormData={setFormData}
            payorOptions={payorOptions}
          />
        );
      case 2:
        return (
          <DynamicForm
            formData={formData}
            setFormData={setFormData}
            testopt={testopt}
          />
        );
      case 3:
        return <ConfirmationPage formData={formData} />;
      default:
        return "Unknown Step";
    }
  };

  return (
    <>
      {showThankYou ? (
        <ThankuPage
          onClose={() => setShowThankYou(false)}
          thanksImg={thanksImg}
        />
      ) : (
        <div
          className="h-screen w-full flex items-center justify-center bg-no-repeat bg-cover bg-center bg-blue-200"
          style={{ backgroundImage: `url(${Bgimg})` }}
        >
          <div className="shadow-lg rounded-2xl overflow-hidden p-0 h-full w-full xs:w-full sm:w-full md:w-[65%] lg:w-[75%] xs:h-full sm:h-full md:h-[85%] lg:h-[90%] bg-blue-100">
            {activeStep < 2 ? (
              <div
                className="block w-full max-w-full h-48 bg-cover bg-center bg-no-repeat mix-blend-normal"
                style={{
                  backgroundImage: `url(${topPic})`,
                  backgroundColor: "inherit",
                }}
              ></div>
            ) : null}

            <div
              className="h-1 bg-green-600 transition-all duration-1000 ease-in-out"
              style={{
                width: `${((activeStep + 1) / steps.length) * 100}%`,
              }}
            />

            <div className="flex flex-col md:flex-row h-full">
              <div
                className="flex-1 p-6 overflow-y-auto no-scrollbar"
                style={{
                  maxHeight:
                    activeStep < 2 ? "calc(85% - 48px)" : "calc(100% - 48px)",
                }}
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="max-w-2xl mx-auto lg:p-6"
                  >
                    {errorMessage && (
                      <div className="w-full max-w-md mx-auto  mb-4 p-4 bg-red-100 border border-red-500 text-red-700 rounded-lg shadow-lg ">
                        <h3 className="font-semibold text-lg">Error</h3>
                        <p>{errorMessage}</p>
                      </div>
                    )}
                    <h2 className="text-base sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-center">{`${testopt.toUpperCase()} TESTING`}</h2>
                    <motion.div
                      key={activeStep}
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -50 }}
                      transition={{ duration: 0.3 }}
                      className="space-y-6"
                    >
                      {getStepContent(activeStep)}
                    </motion.div>

                    <div className="mt-8 mb-11 flex justify-around">
                      {activeStep > 0 && (
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 transition-colors duration-200"
                          onClick={handleBack}
                        >
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="mr-2"
                          />{" "}
                          Back
                        </motion.button>
                      )}
                      {activeStep < steps.length && (
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 transition-colors duration-200"
                          onClick={handleGoHome}
                        >
                          <FontAwesomeIcon icon={faHome} className="mr-2" />{" "}
                          Home
                        </motion.button>
                      )}
                      {activeStep === 0 ? (
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                          onClick={handleNext}
                        >
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="mr-2"
                          />{" "}
                          Next
                        </motion.button>
                      ) : activeStep !== 0 && activeStep < steps.length - 2 ? (
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                          onClick={handleNext2}
                        >
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="mr-2"
                          />{" "}
                          Next
                        </motion.button>
                      ) : activeStep === steps.length - 2 ? (
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                          onClick={handleSubmit}
                        >
                          <FontAwesomeIcon icon={faCheck} className="mr-2" />{" "}
                          Submit
                        </motion.button>
                      ) : null}
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MultiStepRegistrationForms;
