import React from "react";

function PayorInformationForm({ formData, setFormData, payorOptions }) {
  const paymentOption = formData.paymentType || "";

  const handlePayorOptionChange = (e) => {
    const value = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      paymentType: value,
      payorName: value === "self" ? "self" : prevFormData.payorName,
    }));
  };

  const handlePayorSelectionChange = (e) => {
    const selectedPayor = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      payorName: selectedPayor,
    }));
  };

  return (
    <div className="space-y-4">
      <h1 className="text-xl font-bold text-center">Payor Information</h1>

      <label className="block mb-2">Member Id*</label>
      <input
        type="text"
        placeholder="Member Id*"
        name="memberId"
        value={formData.memberId}
        onChange={(e) => setFormData({ ...formData, memberId: e.target.value })}
        className="w-full px-3 py-2 border border-gray-300 rounded-md"
      />

      <label className="block mb-2">Payor Name*</label>
      <select
        name="paymentOption"
        value={paymentOption}
        onChange={handlePayorOptionChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="">Select Payment Option</option>
        <option value="self">Self</option>
        <option value="payor">Payor</option>
      </select>
      {paymentOption === "self" && (
        <div className="text-red-500">
          <span className="font-bold">*</span> You have to pay by yourself,
          Because you choosed self.
        </div>
      )}

      {paymentOption === "payor" && (
        <div>
          <label className="block mb-2">Select Payor</label>
          <select
            name="payorName"
            value={formData.payorName}
            onChange={handlePayorSelectionChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            style={{ maxHeight: "200px", overflowY: "auto" }}
          >
            <option value="">Select a Payor</option>
            {payorOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

export default PayorInformationForm;
