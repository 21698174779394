import React, { useRef } from "react";
import icon from "../../images/icon.png";
import entiretest from "../../images/entiretest.jpg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import formDatas from "../questionData/formDatas.js";
import "./Tests.css";

function Tests() {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -500,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 500,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="mt-10 relative">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${entiretest})`,
          filter: "blur(9.5px)",
        }}
      ></div>
      <div className="relative z-10">
        <div className="relative text-center z-10 p-4">
          <span className="h-6 text-lg text-blue-700 font-roman font-bold uppercase">
            Our Featured Tests
          </span>
          <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-10 tracking-wide">
            <span className="font-semibold font-roman bg-gradient-to-r from-blue-500 via-blue-700 to-purple-700 text-transparent bg-clip-text">
              Take a Quick Survey Here!
            </span>
          </h2>
        </div>
      </div>
      <div className="flex items-center justify-center mt-10 lg:mt-20">
        <button
          onClick={scrollLeft}
          className="relative z-10 text-purple-800 p-4 text-md"
        >
          <FaChevronLeft />
        </button>
        <div
          ref={scrollRef}
          className="flex overflow-x-auto whitespace-nowrap w-full scrollbar-hide mx-2 px-4"
        >
          {Object.keys(formDatas).map((key) => (
            <div
              key={key}
              className="flex-shrink-0 card card-content sm:px-4 w-full sm:w-1/2 lg:w-1/3 p-4 m-auto"
            >
              <div className="bg-blue-300 bg-opacity-30 tracking-wide backdrop-blur-md border border-blue-300 rounded-lg p-4 flex flex-col justify-between items-start shadow-md shadow-blue-400 transition-transform hover:scale-105">
                <div className="flex mb-2 items-center">
                  <img
                    src={icon}
                    alt="Lab Test Icon"
                    className="h-10 w-10 mr-2"
                  />
                </div>
                <div>
                  <h5 className="text-[20px] font-semibold font-sans overflow-x-auto w-full inline-block whitespace-normal overflow-hidden overflow-ellipsis line-clamp-1 text-blue-700">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </h5>
                </div>
                <p className="font-sans text-[19px] text-neutral-700 mb-4 whitespace-normal overflow-hidden overflow-ellipsis line-clamp-3">
                  Identify if you carry any inherited conditions.
                </p>
                <Link
                  to={`/form/${key}`}
                  className="text-blue-900 border border-blue-400 hover:border-blue-900 py-2 px-4 rounded-md"
                >
                  Start Test
                </Link>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={scrollRight}
          className="relative z-10 text-purple-800 p-4 text-md"
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
}

export default Tests;
