import HeroSection from "../heroSection/HeroSection.js";
import Tests from "../tests/Tests.js";
import Workflow from "../workflow/Workflow.js";
import Footer from "../footer/Footer.js";
import Care from "../care/Care.js";
import Feedback from "../feedback/Feedback.js";

function Landing() {
  return (
    <>
      <HeroSection />
      <Tests />
      <Workflow />
      <Care />
      <Feedback />
    </>
  );
}

export default Landing;
