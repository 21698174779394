import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function ThankuPage({ thanksImg }) {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      
      navigate("/");
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  const handleGoHome = () => {
    window.location.href = "/";
  };

  return (
    <div className="h-screen flex items-center justify-center relative overflow-hidden">
      <motion.div
        className="bg-white p-6 sm:p-8 lg:p-10 rounded-lg shadow-lg w-full h-full flex flex-col items-center justify-center relative z-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          backgroundImage: `url(${thanksImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex-grow flex items-center justify-center" />

        <button
          onClick={handleGoHome}
          className="absolute bottom-10 left-1/2 transform -translate-x-1/2 inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-2xl group bg-gradient-to-br from-teal-300 to-lime-300 group-hover:from-teal-300 group-hover:to-lime-300 dark:text-black dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800 px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 group-hover:bg-opacity-0"
        >
          <FontAwesomeIcon icon={faHome} className="mr-4 ml-4 text-xl" />
        </button>
      </motion.div>
    </div>
  );
}

export default ThankuPage;
